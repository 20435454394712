import React, { useContext } from 'react'
import {
  CKrNewsletterModal,
  CLocaleSwitcher,
  CReferencesModal,
  CTheFooter,
  CTheHeader,
  CExitIntentModal,
  proximaFont,
} from 'ui'
import { CraftGlobals } from 'tsconfig/craft-types'
import { HeaderContext } from 'ui/context/header'
import { MastheadContext } from 'ui/context/masthead'
import { useSiteInfo } from 'shared'

type Props = {
  children: React.ReactNode
  global: CraftGlobals
}

const DefaultLayout = ({ children, global }: Props) => {
  const siteInfo = useSiteInfo()
  const headerContext = useContext(HeaderContext)
  const mastheadContext = useContext(MastheadContext)

  return (
    <main
      className={`${proximaFont.variable} ${
        siteInfo.isKR && 's-word-break-keep-all'
      }`}
      dir={siteInfo.dir}
    >
      <CTheHeader
        isVisible={headerContext?.isVisible}
        position={headerContext?.position}
        theme={headerContext?.theme}
        isClear={headerContext?.isClear || mastheadContext?.isIntersecting}
        isClearDark={headerContext?.isClearDark}
        hasProgressBar={headerContext?.hasProgressBar}
        countryCode={siteInfo.iso}
        backendUrl={process.env.NEXT_PUBLIC_DISCOVERICL_BACKEND_URL}
        brand={siteInfo.branded}
        doctorsUrl={siteInfo.doctorUrl}
        quizUrl={siteInfo.quizUrl}
        baseDomain={process.env.NEXT_PUBLIC_DISCOVERICL_BASE_DOMAIN}
        discoveryUrl={siteInfo.discoverUrl}
        fullDomain={'/'}
      />
      {children}
      <CLocaleSwitcher
        open={headerContext?.showLocaleSwitcher ?? false}
        onClose={() => headerContext?.setShowLocaleSwitcher(false)}
        domain={process.env.NEXT_PUBLIC_DISCOVERICL_BASE_DOMAIN ?? ''}
      />
      <CReferencesModal field={global.referencesModal} />
      {siteInfo.isKR && <CKrNewsletterModal />}
      <CTheFooter
        hasNewsletter={siteInfo.isUS}
        hasExtraBelow={['de-DE'].includes(siteInfo.iso ?? '')}
        hasExtraInline={['ko-KR'].includes(siteInfo.iso ?? '')}
        hasStaarLink={['ja-JP'].includes(siteInfo.iso ?? '')}
        fullDomain={''}
        discoveryUrl={siteInfo.discoverUrl}
      />
      {siteInfo.isUS && <CExitIntentModal />}
    </main>
  )
}

export default DefaultLayout
